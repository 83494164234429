// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-flat-page-jsx": () => import("/opt/build/repo/src/templates/flat-page.jsx" /* webpackChunkName: "component---src-templates-flat-page-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-maps-jsx": () => import("/opt/build/repo/src/pages/maps.jsx" /* webpackChunkName: "component---src-pages-maps-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

